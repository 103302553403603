.WorkFreePV_ProduccionAudiovisual{
    padding-top: 100px;
    background-color: #045b6a;
    color:white;
    text-align: center;
}
.WorkFreePV_ProduccionAudiovisual_header_list{
    text-align: left;
 }
 .WorkFreePV_ProduccionAudiovisual_header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media(min-width:900px){
    
    
    .WorkFreePV_ProduccionAudiovisual_title{
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 24px;
    }
    .WorkFreePV_ProduccionAudiovisual_content{
        padding: 0;
        margin: 0;
        padding-top: 30px;
        padding-left: 15%;
        font-size: 20px;
        padding-right: 15%;
    }
    .WorkFreePV_ProduccionAudiovisual_videos{
        padding-top:30px;
        display: flex;
        flex-direction: row;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_title{
        padding-top: 30px;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview{
        font-weight: 400;
        font-size: 30px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_1{
        background-image: url(./image/imagen-1-audiovisual.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
        width: 33.333%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_2{
        background-image: url(./image/imagen-2-audiovisual.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
        width: 33.333%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_3{
        background-image: url(./image/imagen-3-audiovisual.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
        width: 33.333%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videomodal{
        padding-top:130px;
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        width: 70%;
    }
    .WorkFreePV_audiovisual_banner{
        background-image: url(./image/desktop-audiovisual.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 271px;
        padding: 0;    
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_text_cotizar{
        font-size: 45px;
    }
    .WorkFreePV_ProduccionAudiovisual_boton_cotizar{
        border: 2px solid white;
        border-radius: 23px;
        line-height: 24px;
        background-color: #045b6a;
        color: white;
        width: 140px;
        vertical-align: middle;
        height: 60px;
        padding-top:10px;
        text-align: center;
        position: relative;
        font-size: 22px;
        cursor: pointer;
    }
    .WorkFreePV_audiovisual_rrss{
        background-color: white;
        color: #045b6a;
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 32px;
        font-weight: 700;
    }
}