.WorkFreeProducciones{ 
  
    background-color:#045b6a;
    display: block; 
    color:white;
    padding-top:40px;
    padding-left:100px;
    padding-right: 100px;
   
}

.WorkFreeProducciones_slang{
    text-align: justify;
    width: 50%;
    padding:10px;
    padding-top: 50px;
  }
  .videoProductoraContainer{
    padding:20px;
    width:50%;
  }
  


.WorkFreeProducciones_services{  
    display: block;  
    box-sizing: border-box;
  }
  
  
  #WorkFree_Produccion{
    background-image: url('../media/productora/cuadroproductora2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  #WorkFree_Matrimonios{
    background-image: url('../media/productora/cuadromatrimonio.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  #WorkFree_Eventos{
    background-image: url('../media/productora/cuadroevento2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  #WorkFree_Marketing{
    background-image: url('../media/productora/cuadromarketing2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .WorkFreeProducciones_service_subtitle{
    font-size: 1.2em;
    font-weight: bolder;
    box-sizing: border-box;
  }
.WorkFreeProducciones_service_link{
    color:white;

    padding:10px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #045b6a;
    color:white;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
  }
  .WorkFreeProducciones_service_box{ 
      display: block;  
      padding: 10px;

      text-align:center;
      border-radius: 15px;
      color:#045b6a;
      margin:auto;
      margin-top:15px;
      padding-bottom: 20px;
      box-sizing: border-box;
  }

.WorkFreeProducciones_services_block{

    display: flex;

  }

  .WorkFreeProducciones_service_title{ 
    font-size: 1.5em;
    font-weight: bolder;
    padding:0;
    box-sizing: border-box;
   }
  
  
   .WorkFreeProducciones_services_social{  
    text-align: center;
    padding-top:40px;
    padding-bottom:10px;
  }
  
  .WorkFreeProducciones_services_social2{  
    text-align: center;
    padding-bottom:40px;
    padding-top:40px;
    box-sizing: border-box;
  }
  .WorkFreeProducciones2{
    background-color: #045b6a;
     color:white;
     text-align: justify;
     font-weight: bold;
     padding-top:100px; 
    
     
 }
 .WorkFreeProducciones3{
  background-color: #045b6a;
   color:white;
   text-align: justify;
   font-weight: bold;
   padding-top: 100px;
}
 .WorkFreeProducciones_title{
   padding-top:70px;
   font-weight: bolder;
 }
 


  @media(max-width:900px){
    .WorkFreeProducciones_services{  
     margin:20px
    }
    .WorkFreeProducciones_service_link{
      padding-left: 50px;
      padding-right: 50px;
      box-sizing: border-box;
    }
    .WorkFreeProducciones_service_link_box{
      padding-top:20px;
    }
    .WorkFreeProducciones2{
      padding-top: 100px;
      max-width: 100%;
    }
    .WorkFreeProducciones{
      max-width: 100%;
      padding-top:100px;
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
      box-sizing: border-box;
    }
    .WorkFreeProducciones_title{
        box-sizing: border-box;
        padding-left: 10px;
        padding-top:10px;
        width: 100%;
        font-size: 1.3em;
    }
    .WorkFreeProducciones_slang{
        padding-left:10px;
        padding-right: 10px;
        padding-top: 0px;
        width:100%; 
    }
    .videoProductoraContainer{
        width: 100%;
    }
    .WorkFreeProducciones_services_block{
        display: block;
        max-width: 100%;
        box-sizing: border-box;
    }
    .WorkFreeProducciones3{
      font-size: 18px;
      max-width: 100%;
    }
    .WorkFreeProducciones_block_11{
      width: 100%;
    }
}