.WorkFreeProducciones_Matrimonios{

    background-image: url('../media/headercononda.svg');
    background-size: cover;
    background-repeat: repeat-x;
    color:white;
    padding-left: 10px;
    padding-bottom: 15px;
    padding-top:150px;
    max-width: 100%;
  }

.WorkFreeProducciones_Matrimonios_title{
    padding-left: 10px;
    color:white;
    padding:10px;
    text-align:center;
  }
  .WorkFreeProducciones_Matrimonios_title2{
    padding-left: 10px;
    color:#045b6a;
  }
  .WorkFreeProducciones_Matrimonios_body{
    display: flex;
    padding-left:50px;
  }
  .WorkFreeProducciones_Matrimonios_body_2{
    color:#045b6a;
    width: 40%;
  }
  
  .WorkFreeProducciones_Matrimonios_body_1{
    width: 60%;
    margin-right: 20px;
  }
  
  .WorkFreeProducciones_Matrimonios_body_3{
   padding-bottom: 15px;
   padding-left:50px;
  }
  
  .WorkFreeProducciones_Matrimonios_body_4{
    display:flex;
    
  
  }
  .WorkFreeProducciones_Matrimonios_body_5{
    width: 60%;
    padding-left: 10px;
    color:#045b6a;
    box-sizing: border-box;
  
  }
  .WorkFreeProducciones_Matrimonios_body_6{
    width: 40%;
  }
  
  .WorkFreeProducciones_Matrimonios_body_img{
    height: 150px;
    width: 75%;
  }
  
  .WorkFreeProducciones_Matrimonios_contact{ 
    display: block;  
    padding-top: 60px;
    text-align: center;
    background-image: url('../media/finalpagina.svg');
    background-repeat: repeat-x;
    background-size: cover;
    padding-bottom:15px;
    color:white;
    padding-left:50px;
    font-size: 2em;
  }
  
  .detallesQueMarcanDiferencia{
    font-family: Sailing;
    font-size: 60pt;
    color:#045b6a;
    padding:15px;
  }


    .WorkFree_Contact_Header_1{
        
        box-sizing: border-box;
        width:90%;
      }
      .WorkFree_Contact_Header_2{

    }
  @media(max-width:900px){
    .WorkFreeProducciones_Matrimonios{
        padding: 0px;
        padding-top:100px;
        max-width: 100%;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_title{
        padding: 0;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_body{
        display: block;
        padding-left: 0px;
        box-sizing: border-box;
        
        
      }

    .WorkFreeProducciones_Matrimonios_body_1{
       
        width: 100%;
        text-align: justify;
        padding-left:20px;
        padding-right: 20px;
        box-sizing: border-box;

    }
    .WorkFreeProducciones_Matrimonios_body_2{
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_body_2_row{
        display: flex;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_body_img{
       
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_body_2_cell{
       /** width:70%;**/
        box-sizing: border-box;
    }
    .WorkFreeProducciones_Matrimonios_body_3{
        padding-left: 0px;
        padding-right: 0px;
        background-color: white;
        
        max-width: 100%;
        display: block;
        box-sizing: border-box;
    }
    .detallesQueMarcanDiferencia{
        font-size: 50px;
    }
    .WorkFreeProducciones_Matrimonios_contact{
      /**  max-width: 100%;**/
        box-sizing: border-box;
        font-size: 1em;
        padding-left: 0px;
    }
    .WorkFreeProducciones_Matrimonios_body_4{
        display:block;
        box-sizing: border-box;
      }
      .WorkFreeProducciones_Matrimonios_body_img{
        
        width: 100%;
        height: 100%;
      }
      .WorkFreeProducciones_Matrimonios_body_5{
          width: 100%;
          padding-left: 20px;
          padding-right: 20px;
      }

  }