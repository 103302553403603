body {
    margin: 0;
    background-color: white;
    font-family: 'Poppins';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

/* Poppins latin */ 
@font-face {
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-display: swap;
src: url('./Fonts/poppins.woff2') format('woff2');
}
  
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('./Fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
font-family: Sailing;
src: url('./Fonts/Saling_Cinta.ttf') ;
}

.WorkFreeHolding{
    background-color:#045b6a;
    padding-top: 100px;
    padding-left: 100px;
    padding-right: 100px;
   
}

h3 {
    padding:0;
    margin:0px;
}

h2 {
    padding:0;
    margin:0px;
}
a{
    text-decoration: none;
}
.superpad{
    padding-top: 1250px!important;
}
.VolverArriba{
    box-sizing: border-box;
    
    position:fixed;
    bottom:0px;
    right: -150px; /**TODO Fix */
    color:white;
    z-index: 1500;
    font-weight: bolder;
    font-size: 1.5em;
     /* Border is the new background */
    background: #045b6a;
    opacity: 0.5;
    /* Rotate from top left corner (not default) */
    transform-origin: 0 0;
    transform: rotate(270deg);
    cursor:pointer;
}