.menutoggle{
    z-index: 100;
}
.WorkFree_header {
    display: flex;
    background-color: #045b6a;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    position: fixed;
    z-index: 1000;
    left: 0%;
    top:0%;
    height: 100px;
    width: 100%;
}

.Workfreepv_Header_logo{
    width: 180px;
   
}



.WorkFree_logo{ 
  
    box-sizing: border-box;
}

.WorkFree_menu{ 
    display: flex; 
    height: 80px;
    align-items: center;
    
}
.WorkFree_menu_item{ 
    font-size: 1.4em;
    padding: 3vw;
    color:white;
    
  }
.WorkFree_menu_item:hover{ 
    text-decoration: underline;
    cursor:pointer;
}

.menu_body{
    background-image: url(../menu/base-menu.png);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;

}

.menu_item{
    text-align: left;
    margin-bottom:5px;
    padding-left: 10px;
    padding-top: 20px;
    font-family: 'Poppins'!important;
    font-size: 32px;
}
.menu_subitem{
    margin-top:5px;
    margin-bottom:5px;
    font-size: 20px;
    text-align: left!important;
    padding-left: 50px;
    font-family: 'Poppins'!important;
    padding-top: 25px;

}

@media(max-width:900px) {
    
    .WorkFree_header{
        width: 100%;
       /** max-width: 100%;**/
       box-sizing: border-box;
    }
    .workfreepv_menu{
        box-sizing: border-box;
    }
}