
.WorkFreeOffice_services_social2{ /*CONTACTO*/
  padding-top:50px;
  color:#045b6a;
  padding: 2vw; 
  text-align: center;
}

H2{
  font-weight: bolder;
}
.WorkFree_TitleRed{
  color:red;
}
.ondasuperior{
  background-image: url('../media/header13.svg');
  background-size: cover;
  background-repeat: no-repeat;
  padding:30px;
  margin:0;
}
.ondainferior{
  background-image: url(../media/piepag13.svg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px;
    margin: 0;
}

.WorkFree_body{
  background-color: #045b6a;
   
    padding-left:100px;
    padding-right: 100px;
    
}

.WorkFree{
    display: block;
    color:white;
}

.WorkFree_RRSS{
  padding-left:15px;
  padding-right: 15px;
  cursor: pointer;
}


.WorkFree_slang{
  display: flex;  
  color: white;
  text-align: justify;
  
}


.WorkFree_contact_field_label_matri{
  text-align: right;
  width:50%;
}
.mapaContacto{
  width:100%;

}

.WorkFreeInmobiliaria_services_ad{ 
  display: block;  
  padding:25px;
}

.WorkFree_contact2{ display: flex;  
  background-color:#045b6a;
  display: block;
  color:white;
  padding: 15px;
  padding-top:100px;
  font-size: 20pt
}

.Workfree_footer{ 
  display: flex;  
  padding: 10px;
  padding-top: 15px;
  padding-left:100px;
  padding-right: 100px;
  background-color: #045b6a;
  color:white;
}

.Workfree_rights{
  background-color: #045b6a;
  color: white;
  text-align: center;
}

.Workfree_footer_logo{ 
  border:0;
  padding: 50px;
  background-image: url('../media/WorkFreeLogo.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-origin: padding-box;
  width: 20%;
}
.Workfree_footer_location{ 
  padding-left: 10%;
  display: block;
  width:30%;
}

.Workfree_footer_hours{ 
  display: block;  
  width:30%;
}


.WorkFree_Carousel{
  display:flex;
}

.WorkFree_Carousel_Image{
 width:35%;
 display: none;
 height:300px; 
}

.WorkFreeHoldingCarousel{
  align-items: center;

}

.WorkFree_menu_item{ 
  font-size: 1.3em;
  padding: 3vw;
  color:white;
}

.WorkFree_slang_body{ 
  font-size: 0.9em;
  padding:10px;
}

.WorkFree_CloseVideoButton{
  background-color:#045b6a;
  color:white;
}
.WorkFree_VideoModal{
  text-align:center;
  font-weight: bold;
}

.WorkFree_contact{
padding-top:60px;
}

.WorkFreeProducciones_audiovisual_title{
  padding-top:35px;
  font-weight: bolder;
  text-align: center;
}

.WorkFreeProducciones_block_1{
  padding-left:250px;
  padding-right: 250px;
  text-align: center;
  font-size: 17px;
}

.WorkFreeProducciones_block_2{
  display:flex;
  text-align: center;
}

.WorkFreeProducciones_block_3{
width: 60%;
padding-right: 15px;
}


.WorkFreeProducciones_block_4{
width: 40%;
padding:20px;
}
.video1{
  width:100%;
  border-color: white;
  border: 2px solid;
}
.WorkFreeProducciones_block_5{
  width:100%;
}
.WorkFreeProducciones_block_7{
  display: flex;
}
.WorkFreeProducciones_block_8{
  width:60%;
  padding-right: 15px;
}
.WorkFreeProducciones_block_9{
  width:40%;
}
.textAreaMatrimonio{
  border: 0;
  margin: 10px;
  border-radius: 15px;
  width: 100%;
  height: 200px;
}

.textAreaContacto{
  border: 0;
  margin: 10px;
  border-radius: 15px;
  width: 100%;
  height: 100px;
}

.WorkFreeProducciones_block_6{
  color:#045b6a;
  font-size: 1.25em;
  padding-left: 100px;
  padding-right: 100px;
  font-weight: bold;
}

@media(min-width:900px){
  .WorkFreeProducciones_block_11{
    width:50%;
    margin: 20px;
  }
}
.WorkFreeProducciones_block_12{
  width:33.3%
}
.WorkFreeProducciones_block_13{
  width:25%;
  margin: 10px;
}
.WorkFreeProducciones_block_14{
  width:33%;
  margin: 10px;
  font-weight: bold;
}
.WorkFreeProducciones_block_16{
  width:33%;
  margin: 10px;
  font-size: 1.4em;
}
.WorkFreeProducciones_block_17{
  width:33%;
  font-size: 1.4em;
  font-weight: bold;
}
.WorkFreeProducciones_block_18{
  width:33%;
  font-weight: bold;
}
.WorkFreeProducciones_block_19{
  width:33%;
}
.WorkFreeProducciones_block_15{
  width:33%;
  margin: 10px;
}
.WorkFreeProducciones_block_20{
  width:33%;
  font-size: 1.4em;
  font-weight: bold;
  text-align: left;
}
.WorkFreeProducciones_block_21{
  display: flex;
}

.asflex{
  display: flex;
}
.centerded{
  text-align: center;
}

.Menu_Item_selected{
  text-decoration: underline;
}

.Workfree_Carousel_selected{
  display:block;
}

.WorkFreeOffice_slang{
  text-align: justify;
}

.videoProductora{
  width:100%;
}

.WorkFreeInmobiliaria_services{ 
  display: flex;  
}
.WorkFreeInmobiliaria_carrousel{ 
  width: 45%;
  
}
#WorkFreeInmobiliaria_carrousel_img{

  height: 300px;
}




.productoraHeader{
  display:flex;
}

.WorkFree_Contact_Header{
  padding:20px;
  text-align: center;
  font-size:1.5em;
  font-weight: bolder;
}
.WorkFree_contact_Title{
  width:40%;
  font-size: 0.8em;
}
.WorkFree_contact_field{
  display:flex;
  font-size: 0.4em;
}
.WorkFree_contact_field_matri{
 
  font-size: 0.8em;
}
.WorkFree_contact_field_label{
  width:50%;
  text-align: left;
  font-weight: bold;
  margin-right: 20px;

}
.largomensaje{
  font-size: 10px;
}

.contacto_mapa{
  width: 50%;
}
.contacto_formulario{
  width: 50%;
  margin: 50px;
}

.contacto_field{
  border:0;
  margin: 10px;
  height: 30px;
  border-radius: 15px;
  width:100%;
}

.contactoconmapa{
  display: flex;
}

.carousel * {
  max-height: 450px;
  max-width: 800px;
  margin:unset;
}
.FondoVerde{
  background-color: #045b6a;
}


