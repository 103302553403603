@media(max-width:901px){
    .WorkFreePV_ProduccionAudiovisual_title{
        font-size: 40px;
        line-height: 45px;
        font-weight: 400;
    }
    .WorkFreePV_ProduccionAudiovisual_content{
        font-size: 22px;
        line-height: 26px;
        text-align: justify;
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .WorkFreePV_ProduccionAudiovisual_videomodal{
        width: 100%;
        height: 300px;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview{
        display: block;
            font-weight: 400;
            font-size: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_title{
        font-size: 30px;
        padding: 20px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .WorkFreePV_ProduccionAudiovisual_video{
        height: 240px;
        width: 100%;
    }
    .WorkFreePV_ProduccionAudiovisual_videos{
        padding-top: 30px;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_1{
        background-image: url(./image/imagen-3-audiovisual-movil.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 225px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .WorkFreePV_audiovisual_banner{
        background-image: url(./image/movil-audiovisual.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 240px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .WorkFreePV_ProduccionAudiovisual_text_cotizar{
        font-size: 44px;
        font-weight: 700;
    }
    .WorkFreePV_ProduccionAudiovisual_boton_cotizar{
        border: 2px solid white;
        border-radius: 23px;
        line-height: 24px;
        width: 140px;
        background-color: #045b6a;
        color: white;
        vertical-align: middle;
        height: 60px;
        padding-top:10px;
        text-align: center;
        position: relative;
        font-size: 22px;
        cursor: pointer;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_playicon{
        height: 70px;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_playicon:hojver{
        border: 1px gray solid;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_2{
        background-image: url(./image/imagen-2-audiovisual-movil.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 225px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .WorkFreePV_ProduccionAudiovisual_videopreview_img_3{
        background-image: url(./image/imagen-1-audiovisual-movil.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 225px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .WorkFreePV_audiovisual_rrss{
        background-color: white;
        color: #045b6a;
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 12px;
        font-weight: 700;
    }
}