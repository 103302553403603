@media(min-width: 900px){
    .WorkFreePV_Marketing{
        background-color: white;
    }
.WorkFreeProducciones_title_marketing{
        text-align: center;
        font-size:24px;
        background-color: #045b6a;
        color: white;
        padding-top: 100px;
    }
    
.WorkFreePV_marketing_subtitle{
    background-color: #045b6a;
    color: white;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding-top: 40px;
    padding-left: 200px;
    padding-right: 200px;
    padding-bottom: 80px;
    }

.WorkFreePV_marketing_block_1{
    margin-top:40px;
    margin-left: 100px;
    margin-right: 100px;
    background-color: #045b6a;
    color: white;
    display: flex;
    flex-direction: "row";
}
.WorkFreePV_marketing_block_2{
    margin-top:40px;
    margin-left: 100px;
    margin-right: 100px;
    background-color: #045b6a;
    color: white;
    display: flex;
    flex-direction: "row";
    justify-content: right;
}
.WorkFreePV_marketing_block_3{
    margin-top:40px;
    margin-left: 100px;
    margin-right: 100px;
    background-color: #045b6a;
    color: white;
    display: flex;
    flex-direction: "row";
}
.WorkFreePV_marketing_block_1_image{
}
.WorkFreePV_marketing_block_1_image_container{
    width: 448px;
    height: 271px;
}
.WorkFreePV_marketing_block_1_content{
    padding-top: 35px;
    padding-left: 80px;
}
.WorkFreePV_marketing_block_1_title{
    font-size: 24px;
}
.WorkFreePV_marketing_block_1_text{
    font-size: 16px;
    line-height: 23px;
}

.WorkFreePV_marketing_block_2_content{
    text-align: left;
    padding-top: 35px;
    padding-right: 80px;
}
.WorkFreePV_marketing_banner{
    padding-top: 40px;
   /* display: flex;
    justify-content: center;**/
    background-image: url(./image/desktop-marketing.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 40px;
    height: 311px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.WorkFreePV_marketing_text_cotizar{
    font-size: 44px;
    font-weight: 700;
    color: white;
    text-align: center;
}
.WorkFreePV_marketing_boton_cotizar{
    border: 2px solid white;
    border-radius: 23px;
    line-height: 24px;
    background-color: #045b6a;
    color: white;
    width: 140px;
    vertical-align: middle;
    height: 60px;
    padding-top:10px;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
}
.WorkFreePV_marketing_rrss{
    color:#045b6a;
    font-size: 24px;
    padding:40px;
}
.WorkFreePV_rrss{
    color:#045b6a;
    background-color: white;
    font-size: 24px;
    padding:40px;
}
.WorkFreePV_audiovisual_banner{
    
}
.WorkFreePV_ProduccionAudiovisual_video{
    width: 100%;
}
}