/** ALL **/
.WorkFreeOffice{
  padding-left:100px;
  padding-right: 100px;
  color:#045b6a;
  background-color: white;
  padding-top: 150px;

}



.WorkFreeOffice_services{
  display: block;
}

.WorkFreeOffice_title{
  font-size:1.8em;
  font-weight: bolder;
}
.WorkFreeOffice_services{  
  display: flex; 
 }

.WorkFreeOffice_carrousel{ 
    
    width: 50%;
    height: 400px;
    padding: 10px;
}

#WorkFreeOffice_carrousel_img{
   width:100%;
   height:100%;
}
.WorkFreeOffice_services_ad{  
    display: block;  
   
}
.WorkFreeOffice_services_ad_title{ 
  padding: 2vw; 
}

.WorkFreeOffice_services_social{ 
  padding: 20px; 
  text-align: center;
}

.WorkFreeOffice_services_social_text{
  padding: 20px;
  text-align: center;
}

.WorkFree_OpenVideoButton:hover {
  text-decoration: underline;
}
.WorkFreeOffice_services_ad_body{
  padding: 30px;
  font-size: 0.8em;
}
.WorkFreeOfficeCarrousel{
  box-sizing: border-box;
  width:60%;
}
@media(max-width:900px){

  .WorkFreeOffice{
    
    margin:0px;
    padding-top: 100px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    box-sizing: border-box;
  }

  .WorkFreeOffice_title{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.5em;
    width: 100%;
    box-sizing: border-box;
  }
  .WorkFreeOffice_slang{
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  .WorkFreeOffice_services{
    display: block;
    box-sizing: border-box;
  }
  .WorkFreeOffice_services_ad{
    margin-top:20px;
    box-sizing: border-box;
  }
  .WorkFreeOffice_services_social{
    box-sizing: border-box;
  }

  .WorkFreeOfficeCarrousel{
    box-sizing: border-box;
    width:100%;
  }

  .WorkFreeOffice_services_ad_body{
    text-align: center;
    margin: 0;
    display: block;
    box-sizing: border-box;
  }
  .WorkFreeOffice_conocemas{
    font-weight: bolder;
    font-size: 1.5em;
    box-sizing: border-box;
  }

  .WorkFreeOffice_Service_Detail{
    font-weight: bolder;
    font-size: 28px;
    padding-top:10px;
    box-sizing: border-box;
  }
  .WorkFree_VideoModal{
    box-sizing: border-box;
  }
  .WorkFree_OpenVideoButton{
    box-sizing: border-box;
  }
}