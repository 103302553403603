@media(max-width:900px) {

    #WorkFreePv{
        max-width: 100%;
        box-sizing: border-box;
    }
    body{
        background-color: #045b6a;
    }
    .WorkFree_RRSS{
        width: 30px;
        height: 30px;
    }
   

    .WorkFreeHolding{
        background-color:#045b6a;
       
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        box-sizing: border-box;
        
    }

    .WorkFreeHoldingCarousel{
        box-sizing: border-box;

    }
    .ReactModalPortal{
        box-sizing: border-box;
    }
    .WorkFreeHome{
        box-sizing: border-box;
    }
    .ondasuperior{
      /*  background-color: white;
        background-size: contain;
        width:100%;*/
        box-sizing: border-box;
        display: none; /**TODO Fix **/
    }
    .ondainferior {
        width: 100%;
        box-sizing: border-box;
        display: none;/**TODO Fix **/
    }
    .WorkFree_slang{
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
       
        font-size: 1.1em;
    }

  
    
    .videoProductoraContainer{
        padding: 0;
        box-sizing: border-box;
    }
    .wFPMatrimonios{
    }
    .mapaContacto{
    width:100%;
    height: 100%;
    }
    .Workfree_footer{
        display: block;
        
        font-size: 20px;
        width: 100%;
        box-sizing: border-box;
        padding-left: 0px;
        padding-right: 0px;
    }
    .Workfree_footer_location{
        width: 100%;
        text-align: center;
      padding-left: 0px;
      padding-right: 0px;
        box-sizing: border-box;
        margin:0px;
    }
    p{
        margin: 0;
    }
    .Workfree_rights{
        box-sizing: border-box;
        width: 100%;
        font-size: 1.5em;
    
    }
    .Workfree_footer_logo{
        width: 100%;
        text-align: center;
        background-size: cover;
        height: 130px;
        padding-left: 20%;
        box-sizing: border-box;
    }
    .Workfree_footer_hours{
        width: 100%;
        text-align: center;
        box-sizing: border-box;
    }
    .WorkFree_VideoIframe{
        width:300px;
        height: 300px;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_1{
        padding-left:10px;
        padding-right: 10px;
        box-sizing: border-box;
        text-align: justify;
    }
    .WorkFreeProducciones_audiovisual_title{
        padding-top: 5px;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_3{
        width:100%;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_4{
        padding: 0;
        width:100%;
        box-sizing: border-box;
    }
      .WorkFreeProducciones_block_5{
        width: 100%;
        height: 100%;
        border: 0;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_6{
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_7{
        display: block;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_8{
        width:100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_9{
        width:100%;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_block_21{
        display: block;
    }
    .productoraHeader{
        display:block;
        box-sizing: border-box;
    }
    .WorkFreeProducciones_slang{
        width:90%;
        box-sizing: border-box;
        width: 100%;
    }

    
    .videoProductora{
        box-sizing: border-box;
    }
    .WorkFreeOffice_services_social2{
        color:white;
    }
    .WorkFree_Contact_Header{
        font-size: 1.8em;
        text-align: left;
        box-sizing: border-box;
    }
    
    .WorkFree_contact_field_label{
    /*    font-size: 0.3em;*/
        width: 100%;
        text-align: center;
    }
    .WorkFree_contact_field_matri{
        display: block;
        font-size: 1.3em;
    }
    .contacto_mapa{
        width:90%;
        padding: 20px;
    }
    .contacto_formulario{
        margin:5px;
        width:unset;
    }
    .contactoconmapa{
        display: block;
    }
  
    .WorkFreeProducciones_block_6_row{
        display: block;
    }
    .WorkFreeProducciones_block_2{
        display:block;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        
      }
    .WorkFreeProducciones_block_14 {
          width: 100%;
          font-size: 28px;
          font-weight: bold;
      }
      .WorkFreeProducciones_block_15 {
          width: 100%;
          font-size: 28px;
          font-weight: bold;
      }
      .WorkFreeProducciones_block_16 {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
    }
    .WorkFreeProducciones_block_17 {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
    }
    .WorkFreeProducciones_block_18 {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
    }
    .WorkFreeProducciones_block_19 {
        width: 100%;
        font-size: 28px;
        font-weight: bold;
    }
    .WorkFreeProducciones_block_20 {
        width: 100%;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
    }
    .WorkFreeProducciones_block_6_row{
        display: block;
    }
    .WorkFreeProducciones_block_12{
        width: 100%;
    }
    .WorkFreeProducciones_block_6{
        width: 100%;
        padding:0;
        max-width: 100%;
    }
    
    .WorkFreeProducciones_Matrimonios_body_6{
        box-sizing: border-box;
        display: block;
        width: 100%;
      }
      .WorkFree_contact2{
        font-size: 18pt;
      }
}