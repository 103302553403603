@media(max-width: 901px){
    .WorkFreePV_Marketing{
        background-color: white;
    }
.WorkFreeProducciones_title_marketing{
        text-align: center;
        font-size:44px;
        background-color: #045b6a;
        color: white;
        padding-top: 100px;
    }
    
.WorkFreePV_marketing_subtitle{
    background-color: #045b6a;
    color: white;
    font-size: 25px;
    line-height: 29px;
    text-align: justify;
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 80px;
    }

.WorkFreePV_marketing_block_1{
    margin-top:30px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #045b6a;
    color: white;
    display: block;
    flex-direction: "row";
}
.WorkFreePV_marketing_block_2{
    margin-top:30px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #045b6a;
    color: white;
    display: block;
    flex-direction: "row";
    justify-content: right;
}
.WorkFreePV_marketing_block_3{
    margin-top:30px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #045b6a;
    color: white;
    display: block;
    flex-direction: "row";
}
.WorkFreePV_marketing_block_1_image{
}
.WorkFreePV_marketing_block_1_image_container{
    width: 354px;
    height: 271px;
}
.WorkFreePV_marketing_block_1_content{
    padding-top: 35px;
    padding-left: 10px;
    text-align: center;
    padding-bottom: 30px;
}
.WorkFreePV_marketing_block_1_title{
    font-size: 24px;
}
.WorkFreePV_marketing_block_1_text{
    font-size: 12px;
    line-height: 22px;
}

.WorkFreePV_marketing_block_2_content{
    text-align: center;
    padding-top: 35px;
    padding-right: 10px;
    padding-bottom: 30px;
}
.WorkFreePV_marketing_text_cotizar{
    font-size: 44px;
    font-weight: 700;
    color: white;
    text-align: center;
}
.WorkFreePV_marketing_banner{
    background-image: url(./image/movil-marketing.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 40px;
    height: 311px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.WorkFreePV_marketing_boton_cotizar{
    border: 2px solid white;
    border-radius: 23px;
    line-height: 24px;
    background-color: #045b6a;
    color: white;
    width: 140px;
    vertical-align: middle;
    height: 60px;
    padding-top:10px;
    text-align: center;
   
    font-size: 22px;
}
.WorkFreePV_marketing_rrss{
    color:#045b6a;
    font-size: 24px;
    padding:40px;
}
}