@Media(max-width:900px){
    .WorkFreeProducciones_eventos{
        padding: 0px;
        max-width: 100%;
        text-align: justify;
    }
    h1{
        padding:0px;
        margin: 0px;
        font-weight: 400;
        font-size: 45px;
        
    }
    .WorkFreeProduccionesEventos_title{
        margin: 0px;
        font-size: 30px;
        padding-top: 100px;
    }
    .WorkFreeProduccionese_eventos_block_1{
        
        padding: 10px;
        padding-left: 50px;
        padding-right: 50px;
        text-align: justify;
        font-size: 18px;
        line-height: 20px;
      }
    .WorkFreeProducciones_eventos_block_27{
        background-color: white;
        font-size: 12px;
        color:#045b6a;
        padding:0px;
        text-align: center;
        font-weight: bold;
    }
    .WorkFreeProducciones_eventos_block_28{
        margin: 0 auto;
        width:100%;
        background-color: white;
    }
    .WorkFreeProducciones_eventos_boton_cotizar{
        border: 2px solid white;
        border-radius: 13px;
        line-height: 12px;
        background-color: #045b6a;
        color: white;
        width: 80px;
        vertical-align: middle;
        height: 30px;
        padding-top:5px;
        text-align: center;
        position: absolute;
        top: 520px;
        left:40%;
        font-size: 10px;
    }
    .WorkFreeProducciones_eventos_block_6{
        color:#045b6a;
        font-size: 10px;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        
        background-color: white;
        box-sizing: border-box;
      }
      .carousel_mobile_img{
        width: 100%;
      }
}

