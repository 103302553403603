
.WorkFreeProducciones_eventos{
    background-image: url('../media/headerwf08_2.svg');
      background-repeat: repeat-x;
      background-size: cover;
      color:white;
      padding-top: 100px;
      border:0;
}
.WorkFreeProduccionesEventos_title{
  text-align: center;
}
.WorkFreeProduccionese_eventos_block_1{
  padding-left:250px;
  padding-right: 250px;
  text-align: center;
  font-size: 17px;
}
.WorkFreeProducciones_eventos_boton_cotizar{
    border: 2px solid white;
    border-radius: 23px;
    line-height: 24px;
    background-color: #045b6a;
    color: white;
    width: 140px;
    vertical-align: middle;
    height: 60px;
    padding-top:10px;
    text-align: center;
    position: absolute;
    top: 500px;
    left:45%;
    font-size: 22px;
  }
  .WorkFreeProducciones_eventos_block_6{
    color:#045b6a;
    background-color: white;
    font-size: 1.25em;
    padding-left: 100px;
    padding-right: 100px;
    font-weight: bold;
  }
  .WorkFreeProducciones_eventos_block_27{
    background-color: white;
    font-size: 36px;
    color:#045b6a;
    padding:30px;
    text-align: center;
    font-weight: bold;
}
  .WorkFreeProducciones_eventos_block_28{
    margin: 0 auto;
    width:1280px;
  }

